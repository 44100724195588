import {
  MessageCircle,
  Heart
} from 'react-feather';
import kkReport from '../../assets/kk-report.mp4';
import './index.less';

export default ({ type, ...props }) => {
  switch(type) {
    case 'PHOTO':
      return <PhotoPost {...props} />
    case 'SPOT':
      return <SpotPost {...props} />
    case 'REPORT':
      return <ReportPost {...props} />
  }
}

const SpotPost = ({ data }) => {
  return (
    <div className="post-container">
      <div className='header-container'>
        <div className='post-details'>
          <h3 style={{ fontWeight: 600, fontSize: 14 }}>{ data.spot }</h3>
          <h5 style={{ fontWeight: 200, fontSize: 11, opacity: 0.7 }}>{ data.spot }, Chania</h5>
        </div>
      </div>
      <div className='post-body'>
        <img src={data.imgUrl} className="spot-image" />
      </div>
      <div className='post-message'>
        { data.friendCount } friends and { data.strangersCount } others are surfing here right now.
      </div>
    </div>
  )
}

const ReportPost = ({ data, id, hiddenHeader }) => {
  return (
    <div className='post-container' key={id}>
      {!hiddenHeader &&
        <div className='header-container'>
          <img className='user-avatar' src={data.avatarSrc} />
          <div className='post-details'>
            <h3 style={{ fontWeight: 600, fontSize: 14 }}>{ data.postedBy } <span style={{ fontWeight: 400 }}>was in { data.spot }</span></h3>
            <h5 style={{ fontWeight: 200, fontSize: 11, opacity: 0.7 }}>{ data.spot }, Chania</h5>
            <h5 style={{ fontWeight: 200, fontSize: 11, opacity: 0.7, position: 'absolute', left: 0, width: 'calc(100vw - 100px)', top: 19, textAlign: 'right' }}>47 mins ago</h5>
          </div>
        </div>
      }
      <div className='post-body'>
        <video
          autoPlay
          muted
          loop
          playsInline
          src={kkReport}
          className="report-video"
        />
        <Heart stroke='#ED4956' />
        <span>11</span>
        <MessageCircle stroke='#0892D0' />
        <span>6</span>
      </div>
      <div className='post-message'>
        <span style={{ fontWeight: 600, paddingRight: 5 }}>{ data.postedBy }</span>{ data.text }
      </div>
    </div>
  )
}

const PhotoPost = ({ data, id, hiddenHeader }) => {
  return (
    <div className='post-container' key={id}>
      {!hiddenHeader &&
        <div className='header-container'>
          <img className='user-avatar' src={data.avatarSrc} />
          <div className='post-details'>
            <h3 style={{ fontWeight: 600, fontSize: 14 }}>{ data.postedBy }</h3>
            <h5 style={{ fontWeight: 200, fontSize: 11, opacity: 0.7 }}>{ data.spot }, Chania</h5>
            <h5 style={{ fontWeight: 200, fontSize: 11, opacity: 0.7, position: 'absolute', left: 0, width: 'calc(100vw - 100px)', top: 19, textAlign: 'right' }}>23 mins ago</h5>
          </div>
        </div>
      }
      <div className='post-body'>
        <img src={data.src} />
        <Heart stroke='#ED4956' />
        <span>142</span>
        <MessageCircle stroke='#0892D0' />
        <span>11</span>
      </div>
      <div className='post-message'>
        <span style={{ fontWeight: 600, paddingRight: 5 }}>{ data.postedBy }</span>{ data.text }
      </div>
    </div>
  )
}