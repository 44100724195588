import { useState } from 'react';
import { Tabs } from 'antd-mobile';
import ProfilePosts from './ProfilePosts';

import './ProfileTabs.less';

const ProfileReports = () => <div />;
const FriendList = () => <div />;

const ProfileTabs = ({ isHidden }) => {
  const tabs = [
    {
      title: 13,
      subTitle: 'posts',
      content: <ProfilePosts />
    }, {
      title: 26 ,
      subTitle: 'reports',
      content: <ProfileReports />
    }, {
      title: 152,
      subTitle: 'friends',
      content: <FriendList />
    },
  ];

  const [activeTab, setActiveTab] = useState(0);

  const renderTabBar = ({ activeTab, tabs }) => {
    return (
      <div style={{ display: 'flex' }}>
      {tabs.map(({ title, subTitle }, i) => {
        return (
          <div
            key={subTitle}
            style={{ flexGrow: 1, opacity: activeTab != i ? 0.4 : 1 }}
            onClick={() => setActiveTab(i)}
            className="tab-nav-button"
          >
            <p style={{ fontWeight: 600, textAlign: 'center', marginBottom: 3 }}>{ title }</p>
            <p style={{ fontWeight: 400, textAlign: 'center', marginTop: 0, opacity: 0.4 }}>{ subTitle }</p>
          </div>
        );
        })}
      </div>
    );
  }

  return (
    <Tabs
      tabs={tabs}
      page={activeTab}
      style={{ height: '100vh' }}
      tabBarUnderlineStyle={{ display: 'none' }}
      renderTabBar={renderTabBar}
    >
      {tabs.map(({ content, subTitle }) => {
        return (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#fff'
        }} key={subTitle}>
          { content }
        </div>
        );
      })}
    </Tabs>
  );
}

export default ProfileTabs;