import { connect } from 'react-redux';
import { TabBar } from 'antd-mobile';
import { Bell, Crop, Sliders, Coffee, PlusSquare } from 'react-feather'
import { setNotificationsFocused } from '../../redux/actions'
import './TimelineBar.less'

const NotificationsButton = ({ count }) => (
  <div
    style={{
      width: 35,
      height: 35,
      background: '#0892D0',
      borderRadius: 12,
      fontWeight: 500,
      lineHeight: '35px',
      fontFamily: 'Helvetica Neue',
      color: 'white'
    }}
  >{ count }</div>
);

const TimelineBar = ({ data, setNotificationsFocused, notificationsFocused }) => {
  const notificationCount = 16;
  return (
    <div className="bottom-bar">
      <TabBar
        unselectedTintColor="#949494"
        tintColor="#33A3F4"
        barTintColor="white"
      >
        <TabBar.Item
          key="Life"
          icon={<Crop />}
        />
        <TabBar.Item
          key="Koubei"
          icon={<Coffee />}
        />
        <TabBar.Item
          key="Koubei"
          icon={<PlusSquare />}
        />
        <TabBar.Item
          key="Koubei"
          icon={<Sliders />}
        />
        <TabBar.Item
          key="Koubei"
          badge={notificationsFocused ? undefined : notificationCount}
          icon={notificationsFocused ? <NotificationsButton count={notificationCount} /> : <Bell />}
          onPress={() => setNotificationsFocused(!notificationsFocused)}
        />
      </TabBar>
    </div>
  )
}

const mapStateToProps = ({ notificationsFocused }) => {
  return { notificationsFocused }
}

export default connect(mapStateToProps, { setNotificationsFocused })(TimelineBar);