import mitsos from '../../assets/mitsos.jpg';
import riparw from '../../assets/riparw.jpg';
import Post from '../../components/Post'

const posts = [{
  type: 'PHOTO',
  id: 1,
  data: {
    src: riparw,
    avatarSrc: mitsos,
    postedAt: 1586542445701,
    spot: 'Platanias',
    postedBy: 'chosmi',
    text: 'Straight barrelin in dis bish',
    liked: 23,
    shared: 0,
    horned: 41
  }
}]

const ProfilePosts = () => {
  return (
    <div>
      {
        posts.map(({ type, id, ...data }) => {
          return <Post hiddenHeader={true} type={type} key={id} {...data} />;
        })
      }
    </div>
  );
}

export default ProfilePosts;