import { List, Switch } from 'antd-mobile';
import ProfileTabs from './ProfileTabs'
import { Send, MoreHorizontal, UserPlus } from 'react-feather'
import mitsos from '../../assets/mitsos.jpg';

import './index.less'


const Status = (props) => {

  return (
    <div style={{ height: '100%' }} className="profile-container">
      <div style={{ display: 'flex', flexDirection: 'row', width: '100vw', padding: '20px 20px 0' }}>
        <div style={{ flexGrow: 8 }}>
          <img className='user-avatar' src={mitsos} />
        </div>
        <div style={{ flexGrow: 1, padding: 20, opacity: 0.6 }}>
          <Send />
        </div>
        <div style={{ flexGrow: 1, padding: 20, opacity: 0.6 }}>
          <UserPlus />
        </div>
        <div style={{ flexGrow: 1, padding: 20, opacity: 0.6 }}>
          <MoreHorizontal />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100vw', padding: '5px 20px' }}>
        <div style={{ flexGrow: 2, paddingTop: 5 }}>
          <h3 style={{ margin: '5px 0' }}>Steph Curry</h3>
          <p style={{ opacity: 0.4, fontWeight: 300, margin: 0 }}>
            Sfania, Chania Prefecture, Crete, GR
          </p>
        </div>
        <div style={{ width: 50 }}>
          <svg style={{ height: 50, width: 50 }}>
            <circle cx="25" cy="25" r="5" fill="rgb(82, 196, 26)" />
          </svg>
        </div>
      </div>
      <ProfileTabs />
    </div>
  );
};

export default Status