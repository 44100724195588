import { Tabs, WhiteSpace } from 'antd-mobile';
import { connect } from 'react-redux';
import Timeline from './Timeline';
import Profile from './Profile';
import Map from './Map';
import { Mail, MapPin, User } from 'react-feather';

import tribe from '../assets/tribe.svg';
import van from '../assets/hippy-van.svg'

import './index.less';

const Home = ({ notificationsFocused }) => {

  const tabs = [
    { title: <User /> },
    {
      title: (
        <svg width={24} height={24} viewBox="0 0 24 24" stroke="currentcolor" strokeWidth={2.1} fill="none">
          <line x1={0} y1={1.5} x2={24} y2={1.5} />
          <rect width={22} height={14} x={1} y={5} />
          <line x1={0} y1={22.5} x2={24} y2={22.5} />
        </svg>
      )
    },
    { title: <MapPin /> },
    { title: <Mail style={{ opacity: 0.3 }} /> },
  ];

  return (
    <div className="tab-bar-container">
      <Tabs
        tabs={tabs}
        initialPage={1}
        style={{ height: '100vh' }}
        tabBarUnderlineStyle={{ display: 'none' }}
        swipeable={false}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'center',
          height: '100%',
          backgroundColor: '#fff'
        }}>
          <Profile />
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100%',
          overflow: notificationsFocused ? 'auto' : 'hidden',
          backgroundColor: '#fff'
        }}>
          <Timeline />
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          backgroundColor: '#fff'
        }}>
          <Map />
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          backgroundColor: '#fff'
        }}>
          <Mail />
        </div>
      </Tabs>
      <WhiteSpace />
    </div>
  );
}

const mapStateToProps = ({ isHidden, notificationsFocused }) => {
  return { isHidden, notificationsFocused }
}

export default connect(mapStateToProps)(Home);