import { connect } from 'react-redux';
import TimeLineBar from './TimeLineBar';
import mitsos from '../../assets/mitsos.jpg';
import ben from '../../assets/ben.png';
import riparw from '../../assets/riparw.jpg';
import kkReport from '../../assets/kk-report.mp4';
import stalos from '../../assets/stalos.png'
import Post from '../../components/Post'
import NotificationsPanel from './NotificationsPanel'

import './index.less';

const posts = [{
  id: 2,
  type: 'REPORT',
  data: {
    avatarSrc: ben,
    spot: 'Koum Kapi',
    friendCount: 3,
    friends: [
      'Xrysa Michelakis',
      'Greg Morgan'
    ],
    reportSrc: kkReport,
    postedBy: 'homegrownsurfcraft',
    text: 'Lot\'s of chop. Occasional good sets. 3/10',
  }
}, {
  id: 0,
  type: 'SPOT',
  data: {
    spot: 'Stalos',
    friendCount: 7,
    strangersCount: 13,
    imgUrl: stalos,
    friends: [
      'Xrysa Michelakis',
      'Greg Morgan'
    ]
  }
}, {
  type: 'PHOTO',
  id: 1,
  data: {
    src: riparw,
    avatarSrc: mitsos,
    postedAt: 1586542445701,
    spot: 'Platanias',
    postedBy: 'chosmi',
    text: 'Straight barrelin in dis bish',
    liked: 23,
    shared: 0,
    horned: 41
  }
}]

const Timeline = ({ notificationsFocused }) => {
  return (
    <div className={`timeline-container ${notificationsFocused ? 'slide-up' : ''}`}>
      <div className="timeline-body">
        {
          posts.map(({ type, id, ...data }) => {
            return <Post type={type} key={id} {...data} />;
          })
        }
      </div>
      <TimeLineBar />
      <NotificationsPanel />
    </div>
  );
}

const mapStateToProps = ({ notificationsFocused }) => ({ notificationsFocused })

export default connect(mapStateToProps)(Timeline);