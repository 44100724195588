import Parse from 'parse';
import { Button } from 'antd-mobile'
import logo from './assets/logo.svg';
import video from './assets/login.mp4'
import { useState } from 'react';

import './Login.less';

(async () => {
  Parse.initialize("tribal", "12345678");
  //javascriptKey is required only if you have it on server.

  Parse.serverURL = 'http://localhost:1337/parse'

  const user = await Parse.User.logIn("mingus", "asdqwe");
  window.Parse = Parse
})()

const Login = ({ history }) => {

  let [loading, setLoading] = useState(false);

  return (
    <div className="login-container">
      <video
        autoPlay
        muted
        loop
        style={{
          height: '100vh',
          width: '100vw',
          position: 'absolute',
          left: 0,
          top: 0,
          objectFit: 'cover',
          zIndex: 0,
          filter: 'blur(3px)'
        }}
      >
        <source src={video} type="video/mp4" />
      </video>
      <header className="Tribal.surf">
        {!loading && (
          <>
            <img src={logo} className="App-logo" alt="logo" />
            <Button
              type="primary"
              inline={true}
              onClick={() =>
                (function() {
                  setLoading(true);
                  FB.init({
                    appId      : '162257785211264',
                    cookie     : true,
                    xfbml      : true,
                    version    : 'v7.0'
                  });
                  FB.AppEvents.logPageView();
                  FB.login(function(response) {
                    if (response.authResponse) {
                      console.log('Welcome!  Fetching your information.... ');
                    FB.api('/me', function(response) {
                      console.log(response);
                      console.log('Good to see you, ' + response.name + '.');
                      setLoading(false);
                      history.push('home')
                    });
                    } else {
                      setLoading(false);
                      console.log('User cancelled login or did not fully authorize.');
                    }
                  });
                })()
              }
            >
              Login with facebook
            </Button>
            <Button
              type="primary"
              inline={true}
              onClick={() => setLoginVisible}
            >
              Sign up
            </Button>
          </>
        )}
        {loading && 'Loading'}

      </header>
    </div>
  );
}

export default Login;