import {
  TOGGLE_STATUS,
  SET_STATUS,
  SET_NOTIFICATIONS_FOCUSED
} from './actionTypes';

const initialState = {
  isHidden: true,
  notificationsFocused: false
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_STATUS:
      return Object.assign({}, state, { isHidden: !state.isHidden })
    case SET_STATUS:
      return Object.assign({}, state, { isHidden: action.payload.data })
    case SET_NOTIFICATIONS_FOCUSED:
      return Object.assign({}, state, { notificationsFocused: action.payload.data })
    default:
      return state;
  }
}

export default reducer;