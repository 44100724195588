import { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';

const Map = () => {

  const setupGeowatching = (map, { latitude, longitude }) => {
    const getPoint = (long, lat) => ({
      'type': 'Point',
      'coordinates': [long, lat]
    })

    map.on('load', function() {
      // Add a source and layer displaying a point which will be animated in a circle.
      map.addSource('point', {
        'type': 'geojson',
        'data': getPoint(longitude, latitude)
      });

      map.addLayer({
        'id': 'point',
        'source': 'point',
        'type': 'circle',
        'paint': {
          'circle-radius': 5,
          'circle-color': '#007cbf'
        }
      });

      navigator.geolocation.watchPosition((position) => {
        const { latitude, longitude } = position.coords;
        map
          .getSource('point')
          .setData(
            getPoint(longitude, latitude)
          )
      })
    });
  }

  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoibWluZ3VzaGVhZCIsImEiOiJjazlyNXdpODcwcjQzM2hwbjdycjY5anpqIn0.mxWccO9YZI2HEW5coVcQRQ';
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      const map = new mapboxgl.Map({
        container: 'map', // container id
        style: 'mapbox://styles/mapbox/outdoors-v11', // stylesheet location
        center: [coords.longitude, coords.latitude], // starting position [lng, lat]
        zoom: 15 // starting zoom
      });
      setupGeowatching(map, coords);
    });
  }, [])

  return (<div
    id="map"
    style={{
      height: '100vh',
      width: '100vw'
    }}
  />);
}

export default Map;