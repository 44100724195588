import {
  TOGGLE_STATUS,
  SET_STATUS,
  SET_NOTIFICATIONS_FOCUSED
} from './actionTypes'

function toggleStatus() {
  return {
    type: TOGGLE_STATUS
  };
}

function setStatus(status) {
  return {
    type: SET_STATUS,
    payload: {
      data: status
    }
  };
}

function setNotificationsFocused(bool) {
  return {
    type: SET_NOTIFICATIONS_FOCUSED,
    payload: {
      data: bool
    }
  }
}

export {
  toggleStatus,
  setStatus,
  setNotificationsFocused
}