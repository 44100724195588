import { List } from 'antd-mobile';
import mitsos from '../../assets/mitsos.jpg';

import './NotificationsPanel.less'

const notifications = [{
  type: 'COMMENT',
  avatar: mitsos,
  objects: ['chosmi', 'dimos', 'a', 'a'],
  date: '46 mins ago'
}, {
  type: 'LIKES',
  avatar: mitsos,
  objects: ['chosmi', 'dimos', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a'],
  subject: 'photo',
  date: '7 mins ago'
}, {
  type: 'FRIEND_REQUEST_ACCEPTED',
  avatar: mitsos,
  objects: ['chosmi'],
  date: '2 hours ago'
}]

const NotificationThumb = ({ imgSrc, data, color }) => (
  <div className="notification-thumb">
    <img src={imgSrc} style={{ height: 35, width: 35, objectFit: 'cover', borderRadius: 8 }} />
    <div style={{ background: color }}>
      { data.length }
    </div>
  </div>
)
const CommentNotificationThumb = (props) => (<NotificationThumb color={'rgb(8, 146, 208)'} {...props} />);
const LikesNotificationThumb = (props) => (<NotificationThumb color={'rgb(237, 73, 86)'} {...props} />);
const FriendAcceptedNotificationThumb = (props) => (<NotificationThumb color={'rgb(8, 146, 208)'} {...props} />);

const CommentNotification = ({ avatar, objects, date }) => (
  <List.Item thumb={<CommentNotificationThumb data={objects} imgSrc={avatar} />}>
    <b>{objects.slice(0, 2).join(', ')}</b> and { objects.length - 2 } others
    <List.Item.Brief>Commented on your post.</List.Item.Brief>
  </List.Item>
)

const LikesNotification = ({ avatar, objects, date }) => (
  <List.Item thumb={<LikesNotificationThumb data={objects} imgSrc={avatar} />}>
    <b>{objects.slice(0, 2).join(', ')}</b> and { objects.length - 2 } others
    <List.Item.Brief>Liked your post.</List.Item.Brief>
  </List.Item>
)

const FriendAcceptedNotification = ({ avatar, objects, date }) => (
  <List.Item thumb={<FriendAcceptedNotificationThumb data={objects} imgSrc={avatar} />}>
    <b>{objects.slice(0, 2).join(', ')}</b>
    <List.Item.Brief>Accepted your friend request.</List.Item.Brief>
  </List.Item>
)

export default () => (
  <div className="notifications-container">
    {notifications.map(({ type, ...rest }) => {
      switch(type) {
        case 'COMMENT':
          return <CommentNotification {...rest} key={rest.date} />
        case 'LIKES':
          return <LikesNotification {...rest}  key={rest.date} />
        case 'FRIEND_REQUEST_ACCEPTED':
          return <FriendAcceptedNotification {...rest}  key={rest.date} />
      }
    })}
  </div>
)